import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  private url: string = environment.url;
  public tenant: string = ''

  public customer: any = null

  public get customerValue() {
    return this.customer;
  }

  public business: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  setValue() {
    const customer = JSON.parse(localStorage.getItem('customer'))
    if (customer) {
      this.customer = customer;
      this.tenant = customer.tenant_id;
    }
  }

  logout(){
    localStorage.removeItem('customer');
    this.router.navigate(['/customers']);
  }

  //users

  getAllUsers() {
    return this.http.get(`${this.url}/api/control/users?tenant=${this.tenant}`);
  }

  getUserCount() {
    return this.http.get(`${this.url}/api/control/users/data/getCount?tenant=${this.tenant}`);
  }

  getUser(id) {
    return this.http.get(`${this.url}/api/control/users/${id}?tenant=${this.tenant}`).pipe(
      map((response: any) => {
        return response.user;
      })
    );
  }

  createUser(user: any) {
    return this.http.post(`${this.url}/api/control/users?tenant=${this.tenant}`, user);
  }

  updateUser(user: any) {
    return this.http.put(`${this.url}/api/control/users/${user.get('id')}?tenant=${this.tenant}`, user)
  }

  deleteUser(id) {
    return this.http.delete(`${this.url}/api/control/users/${id}?tenant=${this.tenant}`);
  }

  //roles

  getPermissions() {
    return this.http.get(`${this.url}/api/control/permissions?tenant=${this.tenant}`);
  }

  getAllRoles() {
    return this.http.get(`${this.url}/api/control/roles?tenant=${this.tenant}`);
  }

  getAllSelectRoles() {
    return this.http.get(`${this.url}/api/control/roles/data/getList?tenant=${this.tenant}`);
  }

  getRole(id) {
    return this.http.get(`${this.url}/api/control/roles/${id}?tenant=${this.tenant}`).pipe(
      map((response: any) => {
        return response.role;
      })
    );
  }

  createRole(role) {
    return this.http.post(`${this.url}/api/control/roles?tenant=${this.tenant}`, role);
  }

  updateRole(role) {
    return this.http.put(`${this.url}/api/control/roles/${role.id}?tenant=${this.tenant}`, role);
  }

  deleteRole(id) {
    return this.http.delete(`${this.url}/api/control/roles/${id}?tenant=${this.tenant}`);
  }

  //tipoCambio

  getAllRates() {
    return this.http.get(`${this.url}/api/control/tipoCambios?tenant=${this.tenant}`);
  }

  getRate(id: number) {
    return this.http.get(`${this.url}/api/control/tipoCambios/${id}?tenant=${this.tenant}`);
  }

  getCurrentRate() {
    return this.http.get(`${this.url}/api/control/tipoCambios/data/getCurrent?tenant=${this.tenant}`);
  }

  createRate(rate) {
    return this.http.post(`${this.url}/api/control/tipoCambios?tenant=${this.tenant}`, rate);
  }

  updateRate(rate) {
    return this.http.put(`${this.url}/api/control/tipoCambios/${rate.id}?tenant=${this.tenant}`, rate);
  }

  deleteRate(id: number) {
    return this.http.delete(`${this.url}/api/control/tipoCambios/${id}?tenant=${this.tenant}`);
  }


  //Clipping

  //actividad usuarios

  getActivityList() {
    return this.http.get(`${this.url}/api/control/activities?tenant=${this.tenant}`);
  }

  getActivityByPage(query) {
    return this.http.get(`${this.url}/api/control/activities?tenant=${this.tenant}&${query}`);
  }

  getActivityByUser(id) {
    return this.http.get(`${this.url}/api/control/activities/data/getListByUser/${id}?tenant=${this.tenant}`);
  }

  getActivityByLog(id) {
    return this.http.get(`${this.url}/api/control/activities/data/getListByLog/${id}?tenant=${this.tenant}`);
  }

  //organizacion

  getInfoBusiness() {
    return this.http.get(`${this.url}/api/control/informacionEmpresa?tenant=${this.tenant}`)
  }

  saveInfoBusiness(id, data) {
    return this.http.post(`${this.url}/api/control/informacionEmpresa/${id}?tenant=${this.tenant}`, data)
  }

  sendCodePhone(data) {
    return this.http.post(`${this.url}/api/control/sendcode-phone?tenant=${this.tenant}`, data)
  }

  verifiedCode(data) {
    return this.http.post(`${this.url}/api/control/verifycode-phone?tenant=${this.tenant}`, data)
  }

  consultaRuc(ruc) {
    return this.http.get(`${this.url}/api/control/consulta-ruc/${ruc}?tenant=${this.tenant}`)
  }

  saveRuc(data) {
    return this.http.post(`${this.url}/api/control/save-ruc?tenant=${this.tenant}`, data)
  }

  getPayments() {
    return this.http.get(`${this.url}/api/control/my-payments?tenant=${this.tenant}`)
  }

  openPay(data) {
    return this.http.post(`${this.url}/api/control/openpay?tenant=${this.tenant}`, data)
  }

  getCharge() {
    return this.http.get(`${this.url}/api/control/openpay/card?tenant=${this.tenant}`)
  }

  getSuscription() {
    return this.http.get(`${this.url}/api/control/openpay/suscripcion?tenant=${this.tenant}`)
  }

  updateSuscription(data) {
    return this.http.post(`${this.url}/api/control/openpay/suscripcion/update?tenant=${this.tenant}`, data)
  }

  changeCard(data) {
    return this.http.post(`${this.url}/api/control/openpay/card/customer?tenant=${this.tenant}`, data)
  }

  updatePlan(data) {
    return this.http.post(`${this.url}/api/control/openpay/suscripcion/update/plan?tenant=${this.tenant}`, data)
  }

  newPlan() {
    return this.http.get(`/openpay/suscripcion/nuevo-plan`)
  }

  cancelSuscription() {
    return this.http.get(`${this.url}/api/control/openpay/suscripcion/delete?tenant=${this.tenant}`)
  }

  getOffer(data) {
    return this.http.post(`${this.url}/api/control/openpay/suscripcion/get-descuento?tenant=${this.tenant}`, data)
  }

  getNextCharge(){
    return this.http.get(`${this.url}/api/control/proximo-cargo?tenant=${this.tenant}`)
  }

  plansList() {
    const dataBase = [
      { name: 'Usuarios', id: 'User', quantity: 0 },
      { name: 'Personas', id: 'Persona', quantity: 0 },
      { name: 'Medios', id: 'Medio', quantity: 0 },
      { name: 'Clientes', id: 'Cliente', quantity: 0 },
      { name: 'Campañas', id: 'Campaign', quantity: 0 },
      { name: 'Planes de Medio', id: 'PlanMedio', quantity: 0 },
      { name: 'Impactos', id: 'DetallePlanMedio', quantity: 0 }
    ]

    const permissions = [
      { name: 'Sistema experto', id: 110 },
      { name: 'Clipping', id: 13 },
      { name: 'Kanban', id: 1 }
    ]

    return this.http.get(`${this.url}/api/plans`).pipe(
      map(res => {
        return res['data'].map(pl => {
          pl.list = [...pl.quantity].map(q => {
            let find = dataBase.find(db => db.id == q.model)
            return `${q.limit} ${find.name}`
          })
          pl.access = [...pl.permissions].map(p => {
            let find = permissions.find(db => db.id == p.id)
            if (find) {
              return `${find.name}`
            } else {
              return ''
            }
          }).filter(pl => pl)
          return pl
        })
      })
    )
  }

  getListPagination(page) {
    return this.http.get(`${this.url}/api/control/personas?tenant=${this.tenant}${page}`);
  }

}
