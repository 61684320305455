import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { UserService } from "../services/user.service";
import { catchError } from "rxjs/operators";
//import { GoogleLoginService } from "app/services/google-login.service";

@Injectable({
    providedIn: 'root'
})

export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private userService: UserService,
        //private google: GoogleLoginService
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            catchError(error => {

                if ([401, 403].indexOf(error.status) !== -1) {
                    this.userService.logout();
                }
                return throwError(error);
            })
        );
    }
}
