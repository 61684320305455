import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url: string = environment.url;
  public user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public token: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  permissons: any[] = [];

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.setValue();
  }

  setValue() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.token.next(localStorage.getItem('token'));
    if (user) {
      this.user.next(user);
    }
  }

  public get userValue() {
    return this.user.value;
  }

  getAll() {
    return this.http.get(`${this.url}/api/users`);
  }

  getId(id) {
    return this.http.get(`${this.url}/api/users/${id}`).pipe(
      map((response: any) => {
        return response.user;
      })
    );
  }

  create(user: any) {
    return this.http.post(`${this.url}/api/users`, user);
  }

  edit(id, user: any) {
    return this.http.put(`${this.url}/api/users/${id}`, user).pipe(
      tap((response: any) => {
        const userStorage = JSON.parse(localStorage.getItem('user'));
        if (response.user.id === userStorage.id) {
          userStorage.name = response.user.name;
          userStorage.email = response.user.email;
          localStorage.setItem('user', JSON.stringify(userStorage));
          this.user.next(userStorage);
        }
      })
    );
  }

  profile(user: any) {
    return this.http.post(`${this.url}/api/auth/refresh?_method=PUT`, user).pipe(
      tap((response: any) => {
        localStorage.setItem('user', JSON.stringify(response.user));
        this.user.next(response.user);
      })
    );
  }

  delete(id) {
    return this.http.delete(`${this.url}/api/users/${id}`);
  }

  login(user) {
    return this.http.post(`${this.url}/api/auth/login`, user).pipe(
      tap((response: any) => {
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('token', response.token);
        this.token.next(response.token);
        this.user.next(response.user);
      })
    );
  }

  logout() {
    localStorage.clear();
    this.token.next(null);
    this.user.next(null);
    this.router.navigate(['/auth/login']);
  }

  isPermit(slug): boolean {
    let permissions = [];
    if (this.user.value) {
      permissions = this.user.value.permissions.map(p => p.slug);
    }
    return permissions.includes(slug);
  }

  industryList() {
    return this.http.get(`${this.url}/api/industry`).pipe(
      map(res => {
        return res['industry'];
      })
    );
  }

  editIndustry(id, data) {
    return this.http.put(`${this.url}/api/industry/${id}`, data);
  }

  deleteIndustry(id) {
    return this.http.delete(`${this.url}/api/industry/${id}`);
  }

  createIndustry(data) {
    return this.http.post(`${this.url}/api/industry`, data);
  }

  sizeList() {
    return this.http.get(`${this.url}/api/team-size`).pipe(
      map(res => {
        return res['teamSize'];
      })
    );
  }

  editTeamSize(id, data) {
    return this.http.put(`${this.url}/api/team-size/${id}`, data);
  }

  deleteTeamSize(id) {
    return this.http.delete(`${this.url}/api/team-size/${id}`);
  }

  createTeamSize(data) {
    return this.http.post(`${this.url}/api/team-size`, data);
  }

  getPermissions() {
    return this.http.get(`${this.url}/api/permissions`);
  }

  getRoles() {
    return this.http.get(`${this.url}/api/roles`);
  }

  getRoleId(id) {
    return this.http.get(`${this.url}/api/roles/${id}`).pipe(
      map((response: any) => {
        return response.role;
      })
    );
  }

  createRole(role) {
    return this.http.post(`${this.url}/api/roles`, role);
  }

  updateRole(role) {
    return this.http.put(`${this.url}/api/roles/${role.id}`, role);
  }

  deleteRole(id) {
    return this.http.delete(`${this.url}/api/roles/${id}`);
  }

}
