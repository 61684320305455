import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '../services/image.service';
import { InstanceService } from 'src/app/services/instance.service';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  private url: string = environment.url;

  constructor(
    private imageService: ImageService,
    private dbs: InstanceService
  ) { }

  async transform(data: any, type?: string): Promise<string> {
    try {
      let url = '';
      switch (type) {
        case 'user':
          url = `${this.url}/api/control/users/data/displayImage/${data.id}?tenant=${this.dbs.tenant}`
          break;
        case 'logo':
          url = `${this.url}/api/control/informacionEmpresa/displayLogo/${data}?tenant=${this.dbs.tenant}`
          break;
      }
      const imageBlob = await this.imageService.get(url);
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch (error) {
      if (type === 'user') {
        return 'assets/images/default.png';
      } else {
        return 'assets/images/noimage.png';
      }
    }
  }

}
