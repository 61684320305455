import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './date-ago.pipe';
import { SearchPipe } from './search.pipe';
import { ListDetailPipe } from './list-detail.pipe';
import { ImagePipe } from './image.pipe';


@NgModule({
  declarations: [
    DateAgoPipe,
    SearchPipe,
    ListDetailPipe,
    ImagePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DateAgoPipe,
    SearchPipe,
    ListDetailPipe,
    ImagePipe
  ]
})
export class PipeModule { }
