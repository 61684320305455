import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (this.userService.token.value && this.userService.user.value) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    const permissions = this.userService.user.value.permissions.map(p => p.slug);

    if (permissions.indexOf(route.data.permission) !== -1 || route.data.permission === 'all') {

      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

}
